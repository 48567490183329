<template>
  <div>
    <base-section
      id="vizionhome"
      class="lightgrey"
      space="0"
    >
      <div style="text-align:center;">
        Vizion Home Page
        <router-view />
      </div>
    </base-section>
  </div>
</template>

<script>
  // import * as api from '@/functions/api.js'

  export default {
    name: 'SectionMap',
    data: () => ({
    }),
    provide: {
      heading: { align: 'center' },
    },
    computed: {
    },
    mounted () {
      this.initialize()
    },
    methods: {
      initialize () {
      },
    },
  }
</script>
